import {
  RaButton,
  RaHeading,
  RaImage,
  RaPaper,
  RaRichText,
  RaTypography,
} from '@components/core/index.core'

import { AcfNewsItem } from '@typings'

import styles from './ra-card-news.module.scss'

export const RaCardNews = (card: AcfNewsItem) => {
  return (
    <div className={styles['card']}>
      <RaPaper hoverAnimations={['image-zoom', 'shadow']}>
        <div data-animate="true">
          <RaImage
            image={card.image}
            width={card.image?.sizes['medium-width'] || 300}
            height={card.image?.sizes['medium-height'] || 225}
            tornEdge
          />
        </div>
        <div className={styles['card__content']}>
          {card.title && <RaHeading component="h3">{card.title}</RaHeading>}
          {card.description && <RaRichText content={card.description} />}
        </div>
        {card.cta && (
          <div className={styles['card__button']}>
            <RaButton
              link={{ url: card.cta, title: card.cta_title || card.title }}
            />
          </div>
        )}
      </RaPaper>
    </div>
  )
}
